// eslint-disable-next-line import/no-restricted-paths
import { colorPalette as cp } from "./colorPalette";
import { textStyles } from "./textStyles";
import { borderRadii, fontSizes, spacing } from "./tokens";

export type IPipeTheme = typeof themeDark;
export type IPipeComponents = typeof components;

/**
 * The deprecated theme objects will be cleaned up in time.
 */

const components = {
  API: {
    RightColumn: {
      background: cp.Neutrals.Dark[100],
    },
  },
  Alert: {
    IconColor: {
      danger: cp.Other.Red.Red40,
      info: cp.Other.Blue.Blue40,
      success: cp.Other.Green.Green40,
      warning: cp.Other.Orange.Orange40,
    },
    background: cp.Neutrals.Dark[300],
    boxshadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.12)",
    description: cp.Neutrals.Dark[600],
    title: cp.Neutrals.Dark[900],
  },
  App: {
    background: cp.Neutrals.Dark[50],
    text: cp.Neutrals.Dark[900],
  },
  AreaChart: {
    axis: {
      stroke: cp.Neutrals.Dark[600],
    },
    grid: {
      stroke: cp.Neutrals.Dark[600],
    },
    line: {
      stroke: cp.Other.Blue.Blue40,
    },
    linearGradient: {
      from: cp.Other.Blue.Blue40,
      to: cp.Other.Blue.Blue40,
    },
    tooltip: {
      circleInner: {
        fill: cp.Other.Blue.Blue40,
        stroke: cp.Neutrals.Dark.White,
      },
      circleOuter: {
        fill: cp.Other.Blue.Blue40,
        stroke: cp.Other.Blue.Blue40,
      },
      heading: {
        color: cp.Neutrals.Dark.Black,
        font: {
          size: "16px",
          weight: 700,
        },
      },
      line: {
        stroke: cp.Other.Blue.Blue10,
      },
      subHeading: {
        color: cp.Neutrals.Dark[600],
        font: {
          size: "13px",
          weight: 700,
        },
      },
    },
  },
  AssetRating: {
    A: cp.Other.Purple.Purple20,
    AA: cp.Other.Purple.Purple30,
    AAA: cp.Other.Purple.Purple40,
    B: cp.Other.Teal.Teal20,
    BB: cp.Other.Teal.Teal40,
    BBB: cp.Other.Teal.Teal50,
    C: cp.Other.Yellow.Yellow30,
    CC: cp.Other.Yellow.Yellow40,
    CCC: cp.Other.Yellow.Yellow50,
  },
  Avatar: {
    StatusDot: {
      green: cp.Other.Green.Green40,
      orange: cp.Other.Orange.Orange40,
      red: cp.Other.Red.Red40,
    },
  },
  BackButton: {
    hover: cp.Neutrals.Dark[400],
  },
  BarChart: {
    fill: cp.Semantic.Dark.Action[400],
    stroke: cp.Semantic.Dark.Action[400],
  },
  BeingReviewed: {
    descriptionTextColor: cp.Neutrals.Dark[800],
  },
  /* @deprecated Use a per-component color instead. */
  Box: {
    accent: {
      background: cp.Neutrals.Dark[400],
    },
    background: cp.Neutrals.Dark[50],
    border: {
      active: cp.Other.Blue.Blue40,
      default: cp.Neutrals.Dark[400],
      hover: cp.Other.Blue.Blue40,
      modified: cp.Other.Yellow.Yellow40,
    },
    boxshadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.12)",
    boxshadowhover: "0px 6px 8px 0px rgba(0, 0, 0, 0.18)",
    new: {
      background: cp.Neutrals.Dark[100],
      hover: {
        background: cp.Neutrals.Dark[200],
      },
    },
  },

  /** @deprecated see ButtonAria.  */
  Button: {
    primary: {
      background: {
        active: cp.Semantic.Dark.Action[400],
        default: cp.Semantic.Dark.Action[400],
        disabled: cp.Neutrals.Dark[300],
        hover: cp.Semantic.Dark.Action[500],
      },
      loading: cp.Neutrals.Dark.White,
      text: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[700],
        hover: cp.Neutrals.Dark.White,
      },
    },
    secondary: {
      background: {
        active: cp.Neutrals.Dark[300],
        default: cp.Neutrals.Dark[300],
        disabled: cp.Neutrals.Dark[300],
        hover: cp.Neutrals.Dark[400],
      },
      loading: cp.Neutrals.Dark.Black,
      text: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[600],
        hover: cp.Neutrals.Dark.White,
      },
    },
  },

  ButtonAria: {
    danger: {
      background: {
        active: cp.Semantic.Light.Negative[400],
        default: cp.Semantic.Light.Negative[400],
        disabled: cp.Neutrals.Dark[400],
        hover: cp.Semantic.Light.Negative[500],
      },
      border: {
        active: "none",
        default: "none",
        disabled: "none",
        hover: "none",
      },
      color: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[700],
        hover: cp.Neutrals.Dark.White,
      },
    },
    flat: {
      background: {
        active: cp.Neutrals.Light[100],
        default: cp.Neutrals.Light[100],
        disabled: cp.Neutrals.Light[400],
        hover: cp.Neutrals.Light[200],
      },
      border: {
        active: "none",
        default: "none",
        disabled: "none",
        hover: "none",
      },
      color: {
        active: cp.Neutrals.Light[900],
        default: cp.Neutrals.Light[900],
        disabled: cp.Neutrals.Dark.White,
        hover: cp.Neutrals.Light[900],
      },
    },
    outline: {
      background: {
        active: "none",
        default: "none",
        disabled: "none",
        hover: "none",
      },
      border: {
        active: cp.Neutrals.Dark[300],
        default: cp.Neutrals.Dark[400],
        disabled: cp.Neutrals.Dark[300],
        hover: cp.Neutrals.Dark[500],
      },
      color: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[600],
        hover: cp.Neutrals.Dark.White,
      },
    },
    primary: {
      background: {
        active: cp.Semantic.Light.Action[400],
        default: cp.Semantic.Light.Action[400],
        disabled: cp.Neutrals.Dark[400],
        hover: cp.Semantic.Light.Action[500],
      },
      border: {
        active: "none",
        default: "none",
        disabled: "none",
        hover: "none",
      },
      color: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[700],
        hover: cp.Neutrals.Dark.White,
      },
    },
    secondary: {
      background: {
        active: cp.Neutrals.Dark[300],
        default: cp.Neutrals.Dark[300],
        disabled: cp.Neutrals.Dark[400],
        hover: cp.Neutrals.Dark[400],
      },
      border: {
        active: "none",
        default: "none",
        disabled: "none",
        hover: "none",
      },
      color: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[300],
        hover: cp.Neutrals.Dark.White,
      },
    },
    treasuryDanger: {
      background: {
        active: "#DF3E3E",
        default: "#DF3E3E",
        disabled: cp.Neutrals.Dark[400],
        hover: cp.Semantic.Light.Negative[500],
      },
      border: {
        active: "none",
        default: "none",
        disabled: "none",
        hover: "none",
      },
      color: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[700],
        hover: cp.Neutrals.Dark.White,
      },
    },
    treasurySuccess: {
      background: {
        active: "#389F6B",
        default: "#389F6B",
        disabled: cp.Neutrals.Dark[400],
        hover: "#22924f",
      },
      border: {
        active: "none",
        default: "none",
        disabled: "none",
        hover: "none",
      },
      color: {
        active: cp.Neutrals.Dark.White,
        default: cp.Neutrals.Dark.White,
        disabled: cp.Neutrals.Dark[700],
        hover: cp.Neutrals.Dark.White,
      },
    },
  },
  CSVManager: {
    FilesWrapper: {
      border: {
        color: cp.Neutrals.Dark[300],
      },
    },
  },
  Card: {
    Base: {
      /** tweaked this to 200 instead of 300 since it conflicted with the button color of 300 */
      activeBackground: cp.Neutrals.Dark[200],
      border: cp.Neutrals.Dark[300],
    },
    DataSource: {
      Body: {
        text: cp.Neutrals.Dark[600],
      },
      StatusTag: {
        Label: {
          color: {
            default: cp.Neutrals.Dark[700],
            disconnected: cp.Semantic.Light.Warning[400],
          },
          font: {
            lineHeight: "16px",
            size: "12px",
            weight: 500,
          },
        },
        LoadingSpinner: {
          color: cp.Neutrals.Dark[700],
        },
        background: {
          default: cp.Neutrals.Dark[200],
          disconnected: cp.Other.Yellow.Yellow70,
        },
      },
    },
    Metric: {
      AddSource: {
        text: cp.Semantic.Light.Action[400],
      },
      Delta: {
        percent: cp.Neutrals.Dark[700],
      },
      Header: {
        text: cp.Neutrals.Dark[700],
      },
      Value: {
        empty: cp.Neutrals.Dark[600],
        present: cp.Neutrals.Light.White,
      },
    },
    Todo: {
      Body: {
        text: cp.Neutrals.Dark[600],
      },
      Header: {
        activeText: cp.Neutrals.Light.White,
        text: cp.Neutrals.Dark[700],
      },
    },
  },

  Changelog: {
    Body: {
      text: cp.Neutrals.Dark[800],
    },
    PseudoWindow: {
      background: cp.Neutrals.Dark[50],
      border: cp.Neutrals.Dark[400],
    },
    Title: {
      text: cp.Neutrals.Dark[900],
    },
  },

  Chart: {
    tooltipshadowcolor: cp.Neutrals.Dark.Black,
  },

  // TODO: We need to replace checkbox.
  Checkbox: {
    checked: {
      background: {
        normal: cp.Semantic.Dark.Action[400],
      },
      border: {
        normal: cp.Semantic.Dark.Action[400],
      },
      check: {
        normal: cp.Neutrals.Dark.White,
      },
    },
    unchecked: {
      background: {
        disabled: cp.Neutrals.Dark[200],
        hover: cp.Neutrals.Dark[200],
        normal: cp.Neutrals.Dark[200],
      },
      border: {
        disabled: cp.Neutrals.Dark[200],
        hover: cp.Neutrals.Dark[500],
        normal: cp.Neutrals.Dark[400],
      },
    },
  },

  CircleStatusTag: {
    background: cp.Neutrals.Dark[300],
  },

  Code: {
    Border: {
      color: cp.Neutrals.Dark[400],
      left: {
        color: cp.Other.Blue.Blue40,
      },
    },
    backgroundColor: cp.Neutrals.Dark[100],
    color: cp.Other.Blue.Blue10,
  },

  ConnectYourData: {
    descriptionTextColor: cp.Neutrals.Dark[800],
  },

  ConnectionBadge: {
    Label: {
      color: {
        default: cp.Neutrals.Dark[700],
        warning: cp.Semantic.Light.Warning[400],
      },
      font: {
        lineHeight: "16px",
        size: "12px",
        weight: 500,
      },
    },
    Wrapper: {
      background: {
        default: cp.Neutrals.Dark[50],
        hover: cp.Neutrals.Dark[100],
      },
      border: cp.Neutrals.Dark[300],
    },
  },

  ConnectionStatusBadge: {
    Label: {
      color: {
        default: cp.Neutrals.Dark[700],
        warning: cp.Semantic.Light.Warning[400],
      },
      font: {
        lineHeight: "16px",
        size: "12px",
        weight: 500,
      },
    },
    Wrapper: {
      background: {
        neutral: {
          default: cp.Neutrals.Dark[200],
        },
        warning: {
          default: cp.Semantic.Dark.Warning[100],
        },
      },
      border: {
        default: cp.Neutrals.Dark[300],
        hover: cp.Semantic.Dark.Warning[300],
      },
    },
  },

  CustomerOverviewTable: {
    Fractional: {
      background: cp.Semantic.Light.Action[400],
    },
    Full: {
      background: cp.Neutrals.Dark[400],
    },
    Subtitle: {
      color: cp.Neutrals.Dark[700],
    },
    color: cp.Neutrals.Dark[800],
  },

  DataSources: {
    DataSourceRow: {
      ActionButton: {
        color: cp.Other.Blue.Blue40,
      },
    },
    SubdomainInputContainer: {
      Input: {
        color: cp.Neutrals.Dark[900],
      },
    },
  },

  DatePicker: {
    background: cp.Neutrals.Dark[200],
    backgroundHover: cp.Neutrals.Dark[400],
    border: cp.Neutrals.Dark[400],
    month: cp.Neutrals.Dark[800],
    text: cp.Neutrals.Dark[900],
    weekHeader: cp.Neutrals.Dark[600],
  },

  Declined: {
    descriptionTextColor: cp.Neutrals.Dark[800],
  },

  Demos: {
    FormError: {
      text: cp.Semantic.Dark.Negative[400],
    },
  },

  Dialog: {
    Header: {
      Title: {
        color: cp.Neutrals.Dark[800],
        font: {
          size: "13px",
          weight: 500,
        },
      },
      background: cp.Neutrals.Dark[50],
      border: cp.Neutrals.Dark[200],
    },
    Overlay: {
      background: cp.Neutrals.Dark[50],
    },
    SideColumn: {
      ActionLabel: {
        color: cp.Neutrals.Dark[500],
        font: {
          lineHeight: "20px",
          size: "13px",
          weight: 500,
        },
      },
      Header: {
        background: cp.Neutrals.Dark[100],
      },
      background: cp.Neutrals.Dark[100],
    },
  },

  Divider: {
    box: cp.Neutrals.Dark[300],
    default: cp.Neutrals.Dark[200],
  },

  DropdownMenu: {
    Item: {
      background: {
        default: cp.Neutrals.Dark[200],
        hover: cp.Neutrals.Dark[300],
        selected: cp.Neutrals.Dark[300],
      },
    },
  },

  Dropzone: {
    background: {
      active: cp.Neutrals.Dark[100],
      default: cp.Neutrals.Dark[50],
      hover: cp.Neutrals.Dark[100],
      reject: cp.Semantic.Dark.Negative[400],
    },
    border: {
      accept: cp.Semantic.Dark.Action[400],
      active: cp.Semantic.Dark.Action[400],
      default: cp.Neutrals.Dark[600],
      hover: cp.Semantic.Dark.Action[400],
      reject: cp.Semantic.Dark.Negative[400],
    },
    text: {
      default: cp.Neutrals.Dark[600],
      hover: cp.Semantic.Dark.Action[400],
    },
  },
  EncryptionMessage: {
    text: cp.Neutrals.Dark[600],
  },
  EntityTile: {
    Placeholder: {
      background: cp.Neutrals.Dark[300],
      text: cp.Neutrals.Dark[600],
    },
    Subtitle: {
      text: cp.Neutrals.Dark[600],
    },
    Title: {
      text: cp.Neutrals.Dark[800],
    },
  },

  Error: {
    icon: cp.Neutrals.Dark[500],
    text: cp.Neutrals.Dark[600],
  },

  FinanceCenter: {
    DownloadIcon: {
      color: cp.Neutrals.Dark[700],
    },
    PaymentList: {
      Summary: {
        text: cp.Neutrals.Dark[700],
      },
    },
    SummaryChart: {
      border: cp.Neutrals.Dark[400],
    },
  },

  HelpWidget: {
    background: {
      default: cp.Neutrals.Dark[100],
      hover: cp.Neutrals.Dark[200],
    },
    icon: cp.Neutrals.Dark[500],
  },

  InlineNav: {
    background: {
      hover: cp.Neutrals.Dark[200],
      selected: cp.Neutrals.Dark[100],
    },
    text: {
      default: cp.Neutrals.Dark[700],
      hover: cp.Neutrals.Dark[900],
      selected: cp.Neutrals.Dark[900],
    },
  },

  Input: {
    background: {
      active: cp.Neutrals.Dark.Black,
      default: cp.Neutrals.Dark[200],
      disabled: cp.Neutrals.Dark[50],
      hover: cp.Neutrals.Dark[300],
    },
    border: {
      // TODO: We will want to make this a FocusRing instead of a border when we revisit
      // the component.
      active: cp.Semantic.Light.Action[400],
      default: cp.Neutrals.Dark[400],
      disabled: cp.Neutrals.Dark[300],
      error: cp.Semantic.Dark.Negative[400],
      hover: cp.Neutrals.Dark[400],
      modified: cp.Other.Yellow.Yellow40,
    },
    icon: {
      default: cp.Neutrals.Dark[600],
      error: cp.Semantic.Dark.Negative[400],
    },
    label: {
      default: cp.Neutrals.Dark[600],
      error: cp.Semantic.Dark.Negative[400],
    },
    text: {
      default: cp.Neutrals.Dark[900],
      disabled: cp.Neutrals.Dark[500],
      // TODO: This should be 400 in disabled variant. Clean this up when we replace
      // Input.
      placeholder: cp.Neutrals.Dark[500],
    },
  },

  InvestorOverviewCards: {
    AssetAmount: {
      text: cp.Neutrals.Dark[900],
    },
    Header: {
      text: cp.Neutrals.Dark[600],
    },
    Label: {
      text: cp.Neutrals.Dark[700],
    },
    PaymentSchedule: {
      Payment: {
        amount: cp.Neutrals.Dark[900],
        date: cp.Neutrals.Dark[700],
        title: cp.Neutrals.Dark[600],
      },
      VerticalRule: {
        color: cp.Neutrals.Dark[300],
      },
    },
    Value: {
      text: cp.Neutrals.Dark[900],
    },
  },

  InvestorSupportCard: {
    ContactSupport: {
      text: cp.Neutrals.Dark[600],
    },
    ManagerDetails: {
      icon: cp.Neutrals.Dark[600],
      text: cp.Other.Blue.Blue30,
    },
    ManagerName: {
      text: cp.Neutrals.Dark[900],
    },
    SectionHeader: {
      text: cp.Neutrals.Dark[600],
    },
    SupportHours: {
      text: cp.Neutrals.Dark[600],
    },
  },
  InvestorTrading: {
    AllMarkets: {
      text: cp.Neutrals.Dark[800],
    },
    LastUpdated: {
      description: cp.Neutrals.Dark[600],
      time: cp.Neutrals.Dark[800],
    },
    MarketBox: {
      ChartLegend: {
        mutedColor: cp.Neutrals.Dark[300],
        mutedText: cp.Neutrals.Dark[400],
        text: cp.Neutrals.Dark[700],
      },
      LastIRR: {
        quarterLabel: cp.Neutrals.Dark[600],
        quarterValue: cp.Neutrals.Dark[700],
        volumeLabel: cp.Neutrals.Dark[700],
        volumeValue: cp.Neutrals.Dark[800],
      },
      Title: {
        text: cp.Neutrals.Dark[800],
      },
    },
  },
  InviteTeammateModal: {
    Form: {
      label: cp.Neutrals.Dark[900],
    },
  },
  KeyValueList: {
    Key: {
      text: cp.Neutrals.Dark[600],
    },
    Value: {
      text: cp.Neutrals.Dark[900],
    },
  },

  LetterIcon: {
    text: cp.Neutrals.Dark.White,
  },

  LineChart: {
    axis: {
      label: cp.Neutrals.Dark[400],
      stroke: cp.Neutrals.Dark[400],
    },
    legend: {
      label: cp.Neutrals.Dark[800],
    },
  },
  Link: {
    text: {
      default: cp.Semantic.Dark.Action[400],
      hover: cp.Semantic.Dark.Action[500],
    },
  },
  ListBox: {
    font: {
      lineHeight: "20px",
      secondarySize: "12px",
      size: "13px",
      weight: 500,
    },
    headerLabelBgColor: cp.Neutrals.Dark[100],
    headerLabelTextColor: cp.Neutrals.Dark[800],
    icon: {
      backgroundColor: cp.Neutrals.Dark[100],
      backgroundSize: "40px",
    },
    itemActiveBgColor: cp.Neutrals.Dark[400],
    itemBgColor: cp.Neutrals.Dark[200],
    itemBgColorHover: cp.Neutrals.Dark[300],
    itemDescriptionTextColor: cp.Neutrals.Dark[600],
    itemLabelTextColor: cp.Neutrals.Dark[800],
  },
  Loader: {
    Dots: {
      background: cp.Neutrals.Dark[800],
      primary: cp.Other.Blue.Blue40,
    },
    Gradient: {
      background: "#27282b",
      foreground: "#383838",
    },
  },
  LoadingSpinner: {
    DefaultColor: cp.Semantic.Dark.Action[400],
  },
  LowEndExperiment: {
    Choose: {
      Background: {
        default: cp.Neutrals.Dark[100],
        hover: cp.Neutrals.Dark[200],
      },
      Loading: {
        color: cp.Neutrals.Dark[700],
      },
    },
  },
  ManualOp: {
    Directory: {
      Entry: {
        background: cp.Neutrals.Dark[200],
      },
    },
  },
  MiniHeader: {
    border: cp.Neutrals.Dark[300],
    title: cp.Neutrals.Dark[900],
  },

  /* TODO(md): The modals need to be re-done, why are the colors named this way?? */
  Modal: {
    activeText: cp.Neutrals.Light.White,
    background: cp.Neutrals.Dark[50],
    border: cp.Neutrals.Dark[300],
    divider: cp.Neutrals.Dark[400],
    foreground: cp.Neutrals.Dark[100],
    label: cp.Neutrals.Dark[600],
    link: cp.Neutrals.Dark[700],
    text: cp.Neutrals.Dark[700],
    title: cp.Neutrals.Dark[900],
  },

  ModalPipe: {
    background: cp.Neutrals.Dark[50],
    // TODO(md): We should be using a shadow for this instead of a constant border
    // color. Switch to that once we add the shadow tokens to the application. Work out
    // a usage pattern then.
    border: cp.Neutrals.Dark[300],
    description: cp.Neutrals.Dark[600],
    logo: cp.Neutrals.Dark.White,
    title: cp.Neutrals.Dark[900],
  },
  NavBar: {
    Header: {
      background: cp.Neutrals.Dark[50],
      logo: cp.Neutrals.Dark[800],
    },
    ProfileMenu: {
      background: cp.Neutrals.Dark[100],
    },
    Texticon: {
      active: cp.Neutrals.Dark[800],
      background: {
        default: cp.Neutrals.Dark[100],
        hover: cp.Neutrals.Dark[200],
      },
      inactive: cp.Neutrals.Dark[600],
    },
  },
  Notifications: {
    Background: {
      color: cp.Neutrals.Dark[100],
    },
    CaughtUp: {
      icon: cp.Neutrals.Dark[500],
      text: cp.Neutrals.Dark[800],
    },
    Header: {
      color: cp.Neutrals.Dark[800],
    },
    NotificationItem: {
      content: cp.Neutrals.Dark[800],
      cta: cp.Neutrals.Dark[600],
      hoverBg: cp.Neutrals.Dark[200],
      icon: cp.Neutrals.Dark[500],
    },
    RedDot: {
      color: cp.Other.Red.Red40,
    },
  },

  NumberField: {
    border: cp.Neutrals.Dark[200],
  },
  OrderBox: {
    Container: {
      // Colors from Figma, werent present in cp.
      Left: "#27282B",
      Right: "#2C2E32",
    },
    PayoutInput: "#2C2E32",
    Row: {
      background: {
        hover: cp.Neutrals.Dark[200],
      },
    },

    SelectPayoutMethod: {
      Selection: {
        background: {
          active: cp.Neutrals.Dark[200],
          hover: cp.Neutrals.Dark[200],
        },
      },
    },

    Slider: {
      alert: {
        error: cp.Semantic.Dark.Negative[400],
      },
      percentage: cp.Neutrals.Dark[400],
    },
    // TODO: This should just use button styles.
    SubmitButton: {
      background: {
        default: cp.Semantic.Dark.Positive[400],
        disabled: cp.Neutrals.Dark[200],
        hover: cp.Semantic.Dark.Positive[500],
      },
      border: {
        disabled: cp.Neutrals.Dark[300],
      },
      text: {
        default: cp.Neutrals.Dark[900],
        disabled: cp.Neutrals.Dark[400],
      },
    },
    SuccessfulTrade: {
      label: {
        default: cp.Neutrals.Dark[700],
      },
    },
    background: cp.Neutrals.Dark[100],
    boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.25)",
  },

  PasswordModal: {
    H2: {
      color: cp.Neutrals.Dark[900],
    },
    Instructions: {
      text: cp.Neutrals.Dark[600],
    },
    ResetPasswordLink: {
      color: {
        default: cp.Neutrals.Dark[600],
        hover: cp.Neutrals.Dark[900],
      },
    },
  },

  PaymentSchedule: {
    axis: cp.Neutrals.Dark[400],
    bar: cp.Neutrals.Dark[500],
    flyout: cp.Neutrals.Dark[200],
    flyoutText: cp.Neutrals.Dark[900],
    stroke: cp.Neutrals.Dark[200],
    tick: cp.Neutrals.Dark[200],
    tickLabel: cp.Neutrals.Dark[700],
  },
  PortfolioAssetsTable: {
    AssetCell: {
      background: cp.Neutrals.Dark[100],
      text: cp.Neutrals.Dark[800],
    },
    AssetHeaderCell: {
      text: cp.Neutrals.Dark[700],
    },
    TableBorder: {
      color: cp.Neutrals.Dark[300],
    },
    TradeCell: {
      text: cp.Neutrals.Dark[700],
    },
    TradeHeaderCell: {
      text: cp.Neutrals.Dark[600],
    },
    TradeLine: {
      color: cp.Neutrals.Dark[400],
    },
  },

  ProfileMenu: {
    Link: {
      background: {
        hover: cp.Neutrals.Dark[200],
      },
      icon: cp.Neutrals.Dark[600],
      text: cp.Neutrals.Dark[900],
    },
    Who: {
      text: cp.Neutrals.Dark[800],
    },
    divider: cp.Neutrals.Dark[300],
  },
  ProgressBar: {
    bar: cp.Neutrals.Dark[500],
    progress: cp.Neutrals.Dark[900],
  },
  Radio: {
    circleInnerColor: cp.Neutrals.Dark[100],
    circleInnerColorHover: cp.Neutrals.Dark[100],
    circleInnerColorSelected: cp.Neutrals.Dark[900],
    circleInnerColorSelectedHover: cp.Neutrals.Dark[900],
    circleOuterColor: cp.Neutrals.Dark[300],
    circleOuterColorHover: cp.Neutrals.Dark[400],
    circleOuterColorSelected: cp.Other.Blue.Blue40,
    circleOuterColorSelectedHover: cp.Other.Blue.Blue30,
    font: {
      lineHeight: "20px",
      secondarySize: "12px",
      size: "13px",
      weight: 500,
    },
    primaryTextColor: cp.Neutrals.Dark[800],
    rowBgColor: cp.Neutrals.Dark[100],
    rowBgColorHover: cp.Neutrals.Dark[200],
    secondaryTextColor: cp.Neutrals.Dark[600],
    valueTextColor: cp.Neutrals.Dark[800],
  },
  RadioGroup: {
    font: {
      lineHeight: "20px",
      size: "13px",
      weight: 500,
    },
    headerBgColor: cp.Neutrals.Dark[50],
    headerTextColor: cp.Neutrals.Dark[700],
  },
  ReleaseModal: {
    background: cp.Neutrals.Dark[100],
    border: cp.Neutrals.Dark[300],
    title: cp.Neutrals.Dark[900],
  },
  Scrollbar: {
    hover: cp.Neutrals.Dark[600],
    normal: cp.Neutrals.Dark[500],
  },
  SegmentedControl: {
    background: cp.Neutrals.Dark[100],
    color: cp.Neutrals.Dark.White,
    hover: {
      background: cp.Neutrals.Dark[300],
    },
    selected: {
      background: cp.Neutrals.Dark[400],
    },
  },
  Select: {
    background: {
      active: cp.Neutrals.Dark[200],
      default: cp.Neutrals.Dark[300],
      disabled: cp.Neutrals.Dark[200],
      hover: cp.Neutrals.Dark[300],
    },
    border: {
      active: cp.Semantic.Light.Action[400],
      default: cp.Neutrals.Dark[400],
      disabled: cp.Neutrals.Dark[300],
      error: cp.Semantic.Light.Negative[400],
      hover: cp.Neutrals.Dark[500],
    },
    color: {
      default: cp.Neutrals.Dark[900],
      disabled: cp.Neutrals.Dark[400],
      empty: cp.Neutrals.Dark[600],
      error: cp.Semantic.Light.Negative[400],
      hover: cp.Neutrals.Dark[700],
    },
    title: cp.Neutrals.Dark[600],
  },

  SelectableCard: {
    background: {
      default: cp.Neutrals.Dark[200],
    },
    boxShadow: {
      default: "0px 6px 8px 0px rgba(0, 0, 0, 0.12)",
      hover: "0px 6px 8px 0px rgba(0, 0, 0, 0.18)",
    },
  },

  SideColumn: {
    Details: {
      body: {
        item: {
          font: {
            size: "12px",
            weight: 400,
          },
          text: {
            color: cp.Neutrals.Dark.White,
          },
        },
        label: {
          font: {
            size: "12px",
            weight: 400,
          },
          text: {
            color: cp.Neutrals.Dark[600],
          },
        },
      },
      header: {
        border: {
          color: cp.Neutrals.Dark[400],
        },
        font: {
          size: "13px",
          weight: 600,
        },
        text: {
          color: cp.Neutrals.Dark.White,
        },
      },
    },
    Header: {
      border: cp.Neutrals.Dark[300],
      text: cp.Neutrals.Dark[900],
    },
    Wrapper: {
      background: {
        color: cp.Neutrals.Dark[100],
      },
      border: {
        color: cp.Neutrals.Dark[300],
      },
    },
  },

  Sidebar: {
    ListItem: {
      itemCount: {
        active: cp.Other.Blue.Blue40,
        hover: cp.Other.Blue.Blue40,
        inactive: cp.Neutrals.Dark[600],
      },
      text: {
        active: cp.Neutrals.Dark[800],
        hover: cp.Neutrals.Dark[800],
        inactive: cp.Neutrals.Dark[700],
      },
    },
    SectionHeader: {
      text: cp.Neutrals.Dark[700],
    },
    SubnavLink: {
      background: {
        active: cp.Neutrals.Dark[100],
        hover: cp.Neutrals.Dark[200],
      },
      text: {
        active: cp.Neutrals.Dark[800],
        inactive: cp.Neutrals.Dark[700],
      },
    },
  },
  Slider: {
    Background: {
      disabled: cp.Neutrals.Dark[300],
      selected: cp.Semantic.Dark.Action[400],
      unselected: cp.Neutrals.Dark[400],
    },
    Label: {
      text: cp.Neutrals.Dark[800],
    },
    Thumb: {
      disabled: cp.Neutrals.Dark[400],
      focused: cp.Semantic.Dark.Action[500],
      selected: cp.Neutrals.Dark[900],
      unselected: cp.Neutrals.Dark[800],
    },
    Value: {
      text: cp.Neutrals.Dark[900],
    },
  },

  StackedAreaChart: {
    XAxis: {
      tickLabelColor: cp.Neutrals.Dark[600],
      tickStroke: cp.Neutrals.Dark[400],
    },
    YAxis: {
      tickLabelColor: cp.Neutrals.Dark[600],
      tickStroke: cp.Neutrals.Dark[400],
    },
  },

  StackedBarChart: {
    axis: {
      label: cp.Neutrals.Dark[400],
      stroke: cp.Neutrals.Dark[400],
    },
    bars: {
      bottom: cp.Other.Blue.Blue40,
      top: cp.Other.Teal.Teal40,
    },
    font: {
      color: cp.Neutrals.Dark[600],
      size: "10px",
    },
    legend: {
      label: cp.Neutrals.Dark[800],
    },
    tooltip: {
      bar: cp.Neutrals.Dark[100],
      stroke: cp.Neutrals.Dark[200],
      title: cp.Neutrals.Dark[700],
      value: cp.Neutrals.Dark[800],
    },
  },
  StatusTag: {
    base: {
      blue: cp.Other.Blue.Blue60,
      green: cp.Other.Green.Green60,
      neutral: cp.Neutrals.Dark[400],
      orange: cp.Other.Orange.Orange60,
      purple: cp.Other.Purple.Purple60,
      red: cp.Other.Red.Red60,
      teal: cp.Other.Teal.Teal60,
      yellow: cp.Other.Yellow.Yellow60,
    },
    text: {
      blue: cp.Other.Blue.Blue10,
      green: cp.Other.Green.Green10,
      neutral: cp.Neutrals.Dark[900],
      orange: cp.Other.Orange.Orange10,
      purple: cp.Other.Purple.Purple10,
      red: cp.Other.Red.Red10,
      teal: cp.Other.Teal.Teal10,
      yellow: cp.Other.Yellow.Yellow10,
    },
  },
  Switch: {
    disabled: {
      background: cp.Neutrals.Dark[200],
      circle: cp.Neutrals.Dark[500],
    },
    focusRing: {
      color: cp.Semantic.Dark.Action[400],
    },
    loadingOff: {
      background: cp.Neutrals.Dark[300],
      circle: cp.Neutrals.Dark[800],
    },
    loadingOn: {
      background: cp.Semantic.Dark.Action[300],
      circle: cp.Neutrals.Dark[800],
    },
    off: {
      background: cp.Neutrals.Dark[400],
      circle: cp.Neutrals.Dark.White,
    },
    on: {
      background: cp.Semantic.Dark.Action[400],
      circle: cp.Neutrals.Dark.White,
    },
  },
  TOTPModal: {
    Error: {
      icon: cp.Semantic.Dark.Negative[400],
      text: cp.Neutrals.Dark[600],
    },
    Instructions: {
      text: cp.Neutrals.Dark[600],
    },
    RecoveryCodeCallout: {
      color: {
        default: cp.Neutrals.Dark[600],
        hover: cp.Neutrals.Dark[900],
      },
    },
  },
  TabContainer: {
    background: {
      default: cp.Neutrals.Dark[200],
      selected: cp.Neutrals.Dark[100],
    },
    text: {
      default: cp.Neutrals.Dark[600],
      selected: cp.Neutrals.Dark[700],
    },
  },
  TabSection: {
    TabButton: {
      border: {
        active: cp.Neutrals.Dark[900],
      },
      text: {
        active: cp.Neutrals.Dark[900],
        default: cp.Neutrals.Dark[700],
      },
    },
    border: cp.Neutrals.Dark[100],
  },

  Table: {
    EmptyState: {
      text: cp.Neutrals.Dark[700],
    },
    Header: {
      background: cp.Neutrals.Dark[100],
      text: {
        default: cp.Neutrals.Dark[600],
        hover: cp.Neutrals.Dark[700],
      },
    },
    Row: {
      background: {
        hover: cp.Neutrals.Dark[100],
      },
      border: cp.Neutrals.Dark[100],
      text: {
        default: cp.Neutrals.Dark[900],
      },
    },
    rowHeight: 44,
    text: cp.Neutrals.Dark.White,
  },

  /* @deprecated Use a per-component color instead. This is still here because the Span
   * and Paragraph components use this. */
  Text: {
    button: cp.Neutrals.Dark.White,
    default: cp.Neutrals.Dark[400],
    error: cp.Semantic.Dark.Negative[400],
    link: cp.Other.Blue.Blue40,
    // muted, default, dark taken from legacy
    muted: cp.Neutrals.Dark[600],
    // TODO: need to get rid of this
    oldGradient:
      "linear-gradient(90deg, #B265ED calc(-1.92% / 2), #BC6CD4 calc(6.4% / 2), #C975B4 calc(26.65% / 2), #E28676 calc(59.01% / 2), #F1914F calc(92.12% / 2), #C975B4 calc(100% - 26.65% / 2), #BC6CD4 calc(100% - 6.4% / 2), #B265ED calc(100% - -1.92% / 2))",
    subtitle: cp.Neutrals.Dark[700],
    success: cp.Semantic.Dark.Positive[400],
    title: cp.Neutrals.Dark[900],
    warning: cp.Semantic.Dark.Warning[400],
  },
  TextField: {
    background: {
      active: cp.Neutrals.Dark.Black,
      default: cp.Neutrals.Dark[200],
      disabled: cp.Neutrals.Dark[50],
      hover: cp.Neutrals.Dark[300],
    },
    border: {
      active: cp.Semantic.Light.Action[400],
      default: cp.Neutrals.Dark[400],
      disabled: cp.Neutrals.Dark[300],
      error: cp.Semantic.Light.Negative[400],
      hover: cp.Neutrals.Dark[500],
    },
    color: {
      active: cp.Neutrals.Dark[900],
      default: cp.Neutrals.Dark[900],
      disabled: cp.Neutrals.Dark[500],
      hover: cp.Neutrals.Dark[700],
      placeholder: cp.Neutrals.Dark[600],
    },
    description: cp.Neutrals.Dark[500],
    errorMessage: cp.Semantic.Light.Negative[400],
    iconColor: cp.Neutrals.Dark[800],
    label: cp.Neutrals.Dark[600],
  },

  Toast: {
    Background: cp.Neutrals.Dark[300],
    Dismiss: cp.Neutrals.Dark[500],
    Icon: {
      error: cp.Semantic.Dark.Negative[400],
      info: cp.Semantic.Dark.Action[400],
      success: cp.Semantic.Dark.Positive[400],
    },
    Text: cp.Neutrals.Dark[800],
  },

  Tooltip: {
    background: cp.Neutrals.Dark[300],
    text: cp.Neutrals.Dark[900],
  },
  Treasury: {
    ActivityLog: {
      OnHover: {
        default: cp.Neutrals.Dark[600],
        selected: cp.Neutrals.Dark.White,
      },
      StatusTag: {
        background: cp.Neutrals.Dark[300],
        color: cp.Neutrals.Dark[700],
      },
    },
    Chart: {
      Tooltip: {
        font: {
          color: cp.Neutrals.Dark[600],
        },
        line: cp.Neutrals.Dark[600],
      },
    },
    Color: {
      Green: "#389F6B",
      Neutral: cp.Neutrals.Dark[600],
      Red: "#DF3E3E",
      White: cp.Neutrals.Dark.White,
    },
    MainPane: {
      Header: {
        background: cp.Neutrals.Dark[50],
      },
      background: cp.Neutrals.Dark[50],
    },
    SidePane: {
      Header: {
        background: cp.Neutrals.Dark[300],
      },
      background: cp.Neutrals.Dark[100],
    },
  },
  Trends: {
    down: cp.Other.Red.Red30,
    up: cp.Other.Green.Green30,
  },
  Welcome: {
    AddSubscriptionsTile: {
      background: cp.Neutrals.Dark[300],
      color: cp.Semantic.Dark.Action[400],
    },
    border: {
      default: cp.Other.Blue.Blue40,
      hover: cp.Other.Blue.Blue40,
    },
  },
};

const old = {
  "action": "#5E85D2",
  "action;": "#5E85D2",
  "actionHighlight": "#87A5DE",
  "backgroundStroke": "#81808D",
  "base": {
    background: cp.Neutrals.Dark[50],
    default: "#18191a",
    muted: "#252525",
  },
  "border": { default: "#18191a", muted: "#868686" },
  "button": {
    secondary: {
      background: "#2C2D36",
      border: "#2B2B2B",
      hoverBackground: "#393b48",
      text: "#14151A",
    },
  },
  "command-k": {
    "base": "#303036",
    "cursor": "#151c29",
    "option-hover": "#101113",
  },
  "cyan": "#4EA9BD",
  "danger": "#B63831",
  "danger;": "#B63831",
  "dangerFaded": "rgba(182,56,49,0.11)",
  "expand-side-panel": { base: "#a5a8ab", retract: "rgba(174,179,183,0.3)" },
  "functional": {
    background: { default: "#24252B" },
    border: { "border-default": "#26282c", "border-muted": "#393B48" },
    icon: { default: "#9c9c9c" },
  },
  "goat": {
    background: "#24252B",
    border: "#24252B",
    editText: "#F9F9F9",
    fieldBackgroundActive: "#37373c",
    foreground: "#2C2D36",
    headerBgHover: "#37373c",
    rowHover: "#25252b",
  },
  "goat-table": { "active-row": "#0b0c10", "header": "#08080a", "row": "#000" },
  "green2": "#27AE60",
  "icon": {
    "default": "#727272",
    "muted": "rgba(67, 90, 111, 0.3)",
    "very-muted": "#000",
  },
  "keyboard-shortcut": { default: "#a5a8ab", muted: "#2e2e2e" },
  "layoutNavbarBackground": "#24252B",
  "modalCloseX": "#b6b6b6",
  // TODO: this is used a lot for HR and border colors should move it.
  "navBorder": "#393B48",
  "palette": {
    blue: {
      base: "#5e85d2",
      dark: "#8d96aa",
      lightest: "rgb(57, 59, 72)",
    },
    green: { base: "#6fc59e", dark: "#95d4b8", light: "#1d3429" },
    orange: { base: "#d99f50", dark: "#d1ab85", light: "#362514" },
    red: { base: "#be4343" },
  },
  "progressBarBackground": "#505258",
  "stripesBackground": "#2C2D36",
  "stripesForeground": "#24252B",
  "success": "#28BF7A",
  "success ": "#28BF7A",
  "successFaded": "rgba(40,191,122,0.11)",
  "text": {
    "dark": "#14151a",
    "default": "#fff",
    "emphasized": "#fff",
    "muted": "#8e8e8e",
    // TODO(md) We need to sort out our color situation lol, but right now I need
    // something between muted and default.
    "notsomuted_FIXME": "#b1b1b1",
    "very-muted": "#6a6a6a",
  },
  "tooltip": { text: "#000" },
};

/** this theme is considered source of truth */
export const themeDark = {
  activityLog: {
    color: "#ffffff",
    colorBold: "#f2f2f2",
  },
  borderRadii,
  bw: {
    transparent: "#fff",
    white: "#fff",
  },
  components,
  fontSizes,
  // TODO(benny): what is icon? for some reason its used a lot
  icon: {
    default: "#fff",
    muted: cp.Neutrals.Dark[600],
    subtitle: cp.Neutrals.Dark[700],
  },
  isDark: true,
  /** @deprecated */
  old,
  permissions: {
    choice: {
      active: "#191919",
      hover: "#424242",
    },
  },
  spacing,
  textStyles,
  touchable: {
    font: {
      weight: 600,
    },
    text: {
      active: "#5E85D2",
    },
  },
  transition: "all .2s ease",
};
